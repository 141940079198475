.skeleton {
  position: relative;
  user-select: none;
  background: var(--color-bg-placeholder);
  color: transparent !important;
  animation-delay: 0.5s;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    animation: shimmer 5s infinite;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.05) 20%,
      rgba(#fff, 0.1) 60%,
      rgba(#fff, 0)
    );
    z-index: 6;

    @keyframes shimmer {
      100% {
        transform: translateX(100%);
      }
    }
  }
}

/*
@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }

  80% {
    opacity: 0.6;
  }
}
*/
