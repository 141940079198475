@use "../styles/_text";

#Item {
  position: relative;

  // Description & poster
  .ItemDescription {
    --max-height: calc(var(--sp) * 3 * 8);
    transition: var(--transition);
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: var(--sp-sm);
    padding: 0 var(--sp-sm);
    margin-bottom: var(
      --sp-md
    ) !important; // To avoid the last child 0 while loading
    max-height: var(--max-height);
    overflow: hidden;

    figure {
      position: relative;
      transition: var(--transition);
      aspect-ratio: 2/ 3;
      width: auto;
      height: var(--max-height);
      flex-shrink: 0;
      background: var(--color-bg-placeholder);
      border-radius: var(--sp);
      overflow: hidden;

      &.blurhash {
        cursor: pointer;
      }
    }

    .text {
      display: flex;
      flex-direction: column;
      gap: var(--sp);
      min-width: 0;
      overflow: hidden;

      p {
        transition: var(--transition);
        margin: 0;

        &.release {
          color: var(--color-primary);
        }

        &.empty {
          color: var(--color-border);
        }
      }
    }

    .showMore {
      opacity: 0;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
    }

    &.closed {
      .showMore {
        opacity: 1;
        top: var(--max-height);
        transform: translateY(-100%);
      }
    }

    @media (min-width: 840px) {
      --max-height: calc(var(--sp) * 3 * 8);
      /*
      overflow: initial;
      max-height: 100vh;
      */

      figure {
        display: none;
      }

      p {
        font-size: calc(var(--sp) * 2);
        line-height: 1.75;
      }
    }

    &.open {
      max-height: var(--desc-height);
    }
  }

  .ItemScores {
    display: flex;
    align-items: flex-start;
    gap: var(--sp);
    margin: calc(var(--sp) * -1) 0 var(--sp-md) 0;
    padding: 0 var(--sp-sm);

    a {
      display: flex;
      flex-direction: column;
      gap: var(--sp);
      align-items: center;
      line-height: 1;
      font-size: 0.9rem;
      font-weight: 900;
      color: var(--color-link);
      font-variant-numeric: tabular-nums;
      background: var(--color-separator);
      padding: var(--sp);
      border-radius: var(--sp);

      img {
        aspect-ratio: 1;
        width: 2rem;
        height: auto;
        flex: 0 0 1.15rem;
        border-radius: calc(var(--sp) / 2);
        color: transparent;
      }
    }
  }

  .loading & {
    .ItemDescription {
      overflow: hidden;

      figure {
        @extend .imgPlaceholder;
        border-radius: var(--sp);

        > * {
          opacity: 0;
        }
      }

      .text {
        @extend .tPlaceholderMulti;
        border-radius: var(--sp);

        &::before,
        &::after {
          height: var(--max-height);
        }
      }
    }
  }
}
