.EmojiScore {
  position: relative;
  display: block;

  svg {
    aspect-ratio: 1;
    width: 100%;
    height: auto;
    line-height: 1;
    fill: currentColor;

    path {
      fill: inherit !important;
    }
  }
}
