.Hero.Intro {
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  .text {
    position: relative;
    padding: 0 var(--sp-lg);
    text-align: center;
    text-wrap: balance;
    max-width: calc(var(--max-width) / 1.5);
    color: var(--color-link);
    z-index: 4;
  }

  .item {
    position: absolute;
    top: 0;
    right: var(--sp);
    padding: var(--sp-sm) var(--sp);
    font-family: var(--font-title);
    font-size: 0.75rem;
    line-height: 1;
    text-transform: uppercase;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6), 0 0 2px rgba(0, 0, 0, 0.3);
    letter-spacing: 0.5px;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    text-align: right;
    z-index: 667;
  }

  a {
    color: var(--color-fg);
  }
}
