.searchResults {
  --size: var(--menu);

  @media (max-width: 840px) {
    --size: calc(var(--sp) * 8);
  }

  position: relative;
  display: flex;
  flex-direction: column;
  padding: calc(var(--size) + var(--sp-sm)) var(--sp-sm) 0;
  background: var(--color-bg);
  z-index: 667;
  min-height: 50vh !important;

  // Search box
  .searchBox {
    transition: var(--transition);
    position: fixed;
    top: calc(var(--menu) - var(--sp) - 1px);
    width: 100%;
    max-width: calc(var(--max-width) - 280px - (var(--sp) * 7));
    height: var(--size);

    display: flex;
    align-items: center;
    gap: var(--sp);
    background: var(--color-bg-nav);
    border-bottom: 1px var(--color-separator) solid;
    backdrop-filter: blur(var(--sp));
    z-index: 666;

    @media (max-width: 840px) {
      width: auto;
      top: 0;
      left: 0;
      right: 0;
      padding: 0 var(--sp-sm);
      border-color: var(--color-border);
    }

    &:focus-within {
      background: var(--color-bg);
    }

    .ais-SearchBox {
      flex-grow: 1;

      form {
        input {
          width: 100%;
          flex-grow: 1;
          padding: var(--sp-sm) 0;
          background: none;
          border: none;
          outline: none;
          box-shadow: none;
          line-height: 1;

          color: var(--color-link);
        }

        .ais-SearchBox-loadingIndicator,
        .ais-SearchBox-reset {
          transition: var(--transition);
          position: absolute;
          inset: calc(var(--sp-sm) + 1px) 1px;
          left: auto;
          height: auto;
          //  background: var(--color-fg);
          fill: var(--color-link);
          z-index: 3;
          border-radius: 0;
          padding: 0 var(--sp-sm);

          &[hidden] {
            display: none;
            opacity: 0;
          }
        }

        .ais-SearchBox-reset {
          svg {
            transform: scale(0.7);
          }
        }

        .ais-SearchBox-loadingIndicator {
          z-index: 666;
          display: flex;
          align-items: center;

          svg {
            stroke: var(--color-link);
          }
        }

        .ais-SearchBox-submit {
          display: none;
        }
      }
    }

    .ais-SearchBox-loadingIndicator,
    button {
      background: none;
      border: none;
      padding: var(--sp) 0;
      color: var(--color-link);

      &.toggleFilters {
        padding: 0 var(--sp-sm);
        color: var(--color-link);

        &.active {
          transform: rotate(180deg);
          color: var(--color-primary);
        }
      }
    }
  }

  // Current refinements
  .ais-CurrentRefinements {
    .ais-CurrentRefinements-list {
      display: flex;
      gap: var(--sp-sm);
      margin-bottom: var(--sp-md);
      flex-wrap: wrap;

      li {
        @extend .tSmall;
        display: flex;
        gap: var(--sp);
        flex-wrap: wrap;
        align-items: center;

        span {
          white-space: nowrap;

          &.ais-CurrentRefinements-label {
            color: var(--color-border);
          }

          &.ais-CurrentRefinements-category {
            text-transform: none;
            background: var(--color-bg-placeholder);
            padding: 0 var(--sp);
            border-radius: var(--sp);

            button {
              cursor: pointer;
              background: none;
              height: auto;
              padding: 0 0 0 var(--sp);

              svg {
                width: 1em;
                height: 1em;
              }
            }
          }
        }
      }

      &--noRefinement {
        margin: 0;
      }
    }
  }

  // Results
  .ais-InfiniteHits {
    text-align: center;

    button {
      margin: var(--sp-sm) auto;

      &[disabled] {
        display: none;
      }
    }

    ol {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: var(--sp-sm);
      text-align: left;
      z-index: 2;

      li {
        &:not(:last-of-type) {
          padding-bottom: var(--sp-sm);
          border-bottom: 1px var(--color-separator) solid;
        }

        a {
          position: relative;
          display: flex;
          align-items: center;
          gap: var(--sp-sm);

          figure {
            border-radius: var(--sp);

            img {
              aspect-ratio: 2 /3;
              width: calc(var(--sp) * 8);
              height: auto;
              object-fit: cover;
            }
          }

          .tSmall {
            color: var(--color-border);
            display: block;
          }

          .ais-Highlight {
            font-weight: 500;
          }

          mark {
            color: var(--color-link);
            background: none;
            text-decoration: underline;
            text-decoration-thickness: 2px;
            text-decoration-color: var(--color-secondary);
            text-underline-offset: 0.5em;
          }

          &.icon {
            figure {
              flex-shrink: 0;
              display: grid;
              place-items: center;
              aspect-ratio: 1;
              width: calc(var(--sp) * 5);
              margin: 0 calc(var(--sp) * 1.5);
              background: var(--color-separator);
              border-radius: var(--sp);
              overflow: hidden;
            }

            svg {
              stroke-width: 1px;
            }
          }

          .userList {
            transition: opacity 0.4s ease-in-out;
            position: absolute;
            top: calc(var(--sp) * 0.5);
            left: calc(var(--sp) * 4.5);
            display: grid;
            place-items: center;
            aspect-ratio: 1;
            width: var(--sp-md);
            background: var(--color-secondary);
            border-radius: 100%;

            &.watched {
              background: var(--color-primary);
            }

            svg {
              width: auto;
              height: calc(var(--sp) * 1.5);
              stroke-width: 2px;
            }
          }
        }
      }
    }
  }

  // No results
  .noResults {
    margin: auto;
    padding-bottom: calc(var(--menu) + var(--gap));
    color: var(--color-border);

    p {
      margin-bottom: var(--sp-md);
      text-align: center;

      q {
        display: block;
        color: var(--color-fg);
      }
    }
  }
}
