@use "../../styles/_text";

.Posters {
  position: relative;
  overflow: hidden;
  margin-bottom: var(--gap);

  ul {
    padding: 0 var(--sp-sm);

    &.ordering {
      li {
        &:after,
        .userList {
          opacity: 0;
        }

        a {
          figure {
            @extend .imgPlaceholder;

            > * {
              opacity: 0;
            }
          }
        }

        p {
          > * {
            transition: var(--transition);
            @extend .tPlaceholder;
          }
        }
      }
    }

    li {
      position: relative;
      transition: var(--transition);
      text-align: center;
      border-color: transparent;

      .item {
        transition: var(--transition);
        display: block;
      }

      figure {
        aspect-ratio: 2/3;
        display: block;
        width: 100%;
        height: auto;
        border-radius: var(--sp);
        overflow: hidden;
        background: var(--color-bg-placeholder);

        &.genre {
          aspect-ratio: 4/3;
        }
      }

      strong {
        margin: var(--sp) 0 calc(var(--sp) / 4) 0;
        // color: var(--color-fg);
        font-weight: 500;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .userList {
        transition: opacity 0.4s ease-in-out;
        position: absolute;
        top: var(--sp);
        right: var(--sp);
        display: grid;
        place-items: center;
        aspect-ratio: 1;
        width: var(--sp-md);
        background: var(--color-secondary);
        border-radius: 100%;
        z-index: 4;

        &.watched {
          background: var(--color-primary);
        }

        svg {
          width: auto;
          height: calc(var(--sp) * 1.5);
          stroke-width: 2px;
        }
      }

      .number {
        --size: 1;
        aspect-ratio: 2/3;
        width: 100%;
        height: auto;
        font-family: var(--font-title);
        font-size: 6em;
        font-weight: 700;
        line-height: 1;
        position: absolute;
        right: 0;
        top: 0;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke: 1px;
        background: linear-gradient(to top, var(--color-bg), transparent);
        border-radius: var(--sp);
        z-index: 3;

        display: flex;
        align-items: flex-end;
        justify-content: center;
      }

      .tSmall {
        display: block;
        color: var(--color-border);
      }

      &.placeholder {
        strong,
        .tSmall {
          @extend .tPlaceholder;
        }
      }
    }
  }

  &.empty {
    ul {
      overflow: hidden;
    }
  }

  &.HorizontalScroll {
    @media (min-width: 840px) {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: var(--sp-sm);
        background: linear-gradient(to left, var(--color-bg), transparent);
      }
    }

    ul {
      //margin-left: var(--sp-sm);
      padding-left: 0;
      display: flex;
      gap: var(--sp-sm);
      scroll-padding-left: var(--sp-sm);

      li {
        flex: 0 0 min(36.66%, calc(var(--sp) * 22));
        max-width: min(36.66%, calc(var(--sp) * 22));

        &:first-child {
          margin-left: var(--sp-sm);
        }

        &.more {
          figure {
            display: flex;
            align-items: center;
            justify-content: center;

            padding: 0 var(--sp-md);

            p {
              color: var(--color-border);

              svg {
                margin: var(--sp) auto 0;
              }
            }
          }
        }
      }
    }

    &.login {
      ul {
        overflow: hidden;
        pointer-events: none;
      }
    }
  }

  &.Grid {
    max-width: 100%;
    overflow: hidden;

    ul {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: var(--sp-sm);

      @media (min-width: 600px) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }

      @media (min-width: 1080px) {
        grid-template-columns: repeat(5, minmax(0, 1fr));
      }
    }

    &.Rows {
      ul {
        display: flex;
        flex-direction: column;

        li {
          text-align: left;

          .item {
            display: flex;
            align-items: center;
            gap: var(--sp-sm);

            .userList {
              top: calc(var(--sp) * 0.5);
              left: calc(var(--sp) * 4.5);
              right: auto;
            }

            figure {
              width: calc(var(--sp) * 8);
              flex-shrink: 0;
            }

            p {
              display: flex;
              flex-direction: column;
              min-width: 0;

              strong {
                margin: calc(var(--sp) / 4) 0 0;
                order: 2;
              }
            }
          }
        }
      }
    }

    &.List {
      ul {
        display: flex;
        flex-direction: column;

        li {
          text-align: left;

          &:not(:last-child) {
            &:after {
              transition: var(--transition);
              content: "";
              position: absolute;
              inset: 0;
              top: calc(100% + var(--sp));
              border-bottom: 1px var(--color-bg-widget) solid;
            }
          }

          .item {
            display: flex;
            align-items: center;
            gap: var(--sp-sm);

            &:not(:has(.userList)) {
              &::before {
                content: "";
                aspect-ratio: 1;
                width: calc(var(--sp) * 3);
                box-shadow: 0 0 0 2px var(--color-separator) inset;
                border-radius: 100%;
                flex-shrink: 0;
                margin-right: calc(var(--sp) * -0.5);
              }
            }

            .userList {
              position: static;
              margin-right: calc(var(--sp) * -0.5);
              width: calc(var(--sp) * 3);
              flex-shrink: 0;
            }

            figure {
              aspect-ratio: 1;
              width: calc(var(--sp) * 3);
              flex-shrink: 0;
              border-radius: calc(var(--sp) / 2);
            }

            p {
              flex: 1;
              display: flex;
              align-items: baseline;
              min-width: 0;
              gap: var(--sp);
              overflow: hidden;

              strong {
                margin: 0;
              }

              span {
                flex-shrink: 0;
                margin-left: auto;
              }
            }
          }

          &.placeholder {
            .item {
              @extend .tPlaceholder;

              > * {
                opacity: 0;
              }
            }
          }
        }
      }
    }
  }

  .react-tabs &,
  .ReactModalPortal & {
    margin: 0;

    ul {
      padding: 0;
    }
  }
}
