:root {
  --color-bg: #1b1c29;
  --color-fg: #d5dadb;
  --color-link: #ffffff;
  --color-border: #8d99ae;
  --color-primary: #f72585;
  --color-secondary: #731dd8;
  --color-tertiary: #25eef7;
  --color-subtle: #32344c;
  --color-fg-disabled: #d5dadb66;
  --color-bg-disabled: #f7258566;
  --color-separator: #8d99ae33;
  --color-bg-modal: #1b1c2933;
  --color-bg-nav: #1b1c29aa;
  --color-bg-placeholder: #282936;
  --color-bg-widget: #232435;

  --color-negative: #f72e25;
  --color-neutral: #e7e247;
  --color-positive: #56d605;

  --sp: 8px;
  --sp-sm: calc(var(--sp) * 2);
  --sp-md: calc(var(--sp) * 3);
  --sp-lg: calc(var(--sp) * 4);
  --menu: calc(var(--sp) * 7 + var(--app-bar));
  --app-bar: 18px;
  --gap: calc(var(--sp) * 10);
  --collection: calc(var(--sp) * 11);
  --avatar: calc(var(--sp) * 14);
  --avatar-md: calc(var(--sp) * 8.5);
  --avatar-sm: calc(var(--sp) * 7);
  --feature: calc(var(--sp) * 48);
  --feature-small: calc(var(--sp) * 36);

  --max-width: 1200px;

  --transition: all 0.4s ease-in-out;
  --box-shadow: 1px 0 10px var(--color-bg);

  --font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial,
    sans-serif;
  --font-title: "Oswald", sans-serif;
  --font-stack: 400 calc(var(--sp) * 1.8) / calc(var(--sp) * 3)
    var(--font-family);
}
