#nav {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;

  height: var(--menu);
  background: var(--color-bg-nav);
  border: var(--color-border) solid;
  border-width: 1px 0 0 0;
  backdrop-filter: blur(var(--sp));
  z-index: 668;

  div {
    display: flex;
    width: 100%;
    margin: auto;
    max-width: var(--max-width);
    justify-content: center;
    align-items: center;
    padding: 0 var(--sp-sm);
    gap: var(--sp-sm);

    .wide,
    span {
      display: none;
    }

    .narrow {
      display: grid;
    }

    svg,
    .avatar {
      aspect-ratio: 1;
      width: calc(var(--sp) * 3);
      height: auto;
      stroke-width: 1.5px;
    }

    .avatar {
      transition: var(--transition);
      background: var(--color-bg-placeholder);
      color: transparent;
      border-radius: 100%;
      object-fit: cover;
    }

    button {
      position: relative;
      padding: 0;
      background: none;

      line {
        transition: var(--transition);
      }

      &.menu {
        &::before,
        &::after {
          content: "";
          transition: var(--transition);
          position: absolute;
          top: calc(50% - 1px);
          left: calc(50% - (var(--sp) * 1.5));
          width: calc(var(--sp) * 3);
          height: 2px;
          background: var(--color-link);
          scale: 0;
        }

        &::before {
          transform: rotate(-45deg);
        }

        &::after {
          transform: rotate(45deg);
        }
      }
    }

    > * {
      cursor: pointer;
      flex-grow: 1;
      height: calc(var(--menu) - var(--app-bar));
      display: grid;
      place-items: center;
      color: var(--color-border);
      // overflow: hidden;

      &.hidden {
        transform: translateX(-100%);
        margin-left: calc(var(--menu) * -1);
        opacity: 0;
      }

      &.active {
        pointer-events: none;
        color: var(--color-link);

        .avatar {
          box-shadow: 0 0 0 1px var(--color-link);
          transform: scale(0.85);
        }
      }

      .menuOpen & {
        &.menu {
          color: var(--color-link);

          svg {
            transform: scale(0) rotate(45deg);
          }

          &::before,
          &::after {
            scale: 1;
            opacity: 1;
          }
        }
      }

      .icon {
        path {
          fill: var(--color-border);
        }
      }
    }
  }

  @media (min-width: 840px) {
    position: fixed;
    height: auto;
    top: 0;
    right: 0;
    left: 0;
    bottom: auto;
    border-width: 0 0 1px 0;
    border-color: var(--color-separator);

    div {
      justify-content: flex-end;
      padding: 0 var(--sp-sm);
      gap: var(--sp-lg);

      > * {
        flex-grow: 0;
        width: auto;
        height: auto;

        display: flex;
        align-items: center;
        gap: calc(var(--sp) / 2);

        svg {
          display: none;
        }

        span {
          display: block;
        }

        &.menu {
          display: none;
        }

        &.profile,
        &.search {
          svg {
            display: block;
          }

          span {
            display: none;
          }
        }
      }

      a,
      button {
        &.back {
          display: none;
        }

        &.wide {
          display: flex;
        }

        &.narrow {
          display: none;
        }

        &.logo {
          position: relative;
          margin-right: auto;
          padding: var(--sp-sm) 0;

          svg {
            transition: var(--transition);
            transition-delay: 0.5s;
            aspect-ratio: auto;
            display: block;
            width: auto;
            height: var(--sp-lg);
            z-index: 2;

            g {
              transition: var(--transition);

              path {
                transform-origin: center;
                transition: var(--transition);
                transition-delay: inherit;
              }

              @for $i from 2 through 6 {
                &:nth-child(#{$i}n) {
                  transition-delay: #{$i * 0.05}s;
                }
              }
            }
          }
        }
      }
    }
  }
}
