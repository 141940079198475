.ScoreStars {
  display: flex;
  gap: calc(var(--sp) / 2);

  svg {
    color: var(--color-link);
    width: var(--sp-sm);
    height: auto;
    stroke: var(--color-border);

    &.filled {
      fill: var(--color-primary);
      stroke: var(--color-primary);
    }
  }
}
