@use "_text";

.react-tabs {
  position: relative;
  transition: var(--transition);
  -webkit-tap-highlight-color: transparent;
  margin-bottom: var(--gap);

  &__tab-list {
    // position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    gap: var(--sp-md);
    background: var(--color-bg);
    padding: 0 var(--sp-sm);
    // margin-bottom: var(--sp-md);
    z-index: 666;
    box-shadow: 0 -1px 0 var(--color-separator) inset;
    display: flex;
    scroll-snap-type: x proximity;
    scroll-padding-left: var(--sp-sm);
    overflow-x: auto;
    overflow-y: hidden;
  }

  &__tab {
    @extend .tSubtitle;

    position: relative;
    flex-shrink: 0;
    scroll-snap-align: start;

    display: flex;
    gap: var(--sp);
    transition: var(--transition);
    position: relative;
    cursor: pointer;
    color: var(--color-border);
    padding: var(--sp-sm) 0;
    z-index: 2;

    small {
      font-size: 1em;
      font-weight: 400;
      opacity: 0.5;
    }

    &--selected {
      color: var(--color-link);

      &:after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 1px;
        background: var(--color-link);
        z-index: 1;
      }
    }

    &--disabled {
      color: GrayText;
      cursor: default;
    }

    &:focus {
      outline: none;
    }
  }

  &__tab-panel {
    transition: var(--transition);
    position: relative;
    padding: var(--sp-md) var(--sp-sm) 0;
    opacity: 0;

    &--selected {
      opacity: 1;
    }

    .showMore {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
