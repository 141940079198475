/* rubik-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/rubik-v26-latin-regular.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("../fonts/rubik-v26-latin-regular.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* rubik-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/rubik-v26-latin-500.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("../fonts/rubik-v26-latin-500.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* rubik-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/rubik-v26-latin-600.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("../fonts/rubik-v26-latin-600.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* rubik-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/rubik-v26-latin-700.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("../fonts/rubik-v26-latin-700.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* rubik-900 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Rubik";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/rubik-v26-latin-900.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("../fonts/rubik-v26-latin-900.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* oswald-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Oswald";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/oswald-v49-latin-regular.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("../fonts/oswald-v49-latin-regular.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* oswald-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Oswald";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/oswald-v49-latin-600.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("../fonts/oswald-v49-latin-600.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* oswald-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Oswald";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/oswald-v49-latin-700.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("../fonts/oswald-v49-latin-700.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
