.Quote {
  position: relative;
  margin-bottom: var(--gap);
  overflow: hidden;

  figure {
    position: absolute;
    inset: 0;
    z-index: 1;
    opacity: 0.3;
    border-radius: inherit;
  }

  .text {
    position: relative;
    font-family: var(--font-title);
    font-size: 2.5rem;
    line-height: 1;
    color: var(--color-link);
    transition: var(--transition);
    text-align: center;
    z-index: 2;
    padding: var(--gap) var(--sp-md);

    &::before {
      content: open-quote;
      display: block;
      font-size: 2.25em;
      aspect-ratio: 1;
      width: 1ch;
      height: 1ch;
      margin: auto auto var(--sp-md);
      background: var(--color-primary);
      box-shadow: 0 0 0 var(--sp) var(--color-primary);
      border-radius: 100%;
    }

    p {
      font-weight: 900;
      text-transform: uppercase;
      margin: auto;
      text-wrap: balance;
    }
  }

  .item {
    position: relative;
    z-index: 2;
    text-align: right;
    padding: var(--sp) var(--sp-sm);

    a {
      color: inherit;
    }
  }

  &.Loading {
    .text {
      opacity: 0;
    }
  }
}
