.ModalHeader {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  gap: var(--sp-sm);
  margin-bottom: var(--sp-sm);
  padding: var(--sp-sm) 0;
  border-bottom: 1px var(--color-separator) solid;
  z-index: 2;
  background: var(--color-bg);

  figure {
    aspect-ratio: 1;
    width: calc(var(--sp) * 6);
    border-radius: var(--sp);
    height: auto;
    background: var(--color-bg-placeholder);
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      color: transparent;
    }
  }

  hgroup {
    h2 {
      font-size: 1em;
      color: var(--color-link);
      font-weight: 500;
    }

    h3 {
      color: var(--color-border);
    }
  }

  .close {
    align-self: flex-start;
    cursor: pointer;
    margin-left: auto;
    height: 100%;
    color: var(--color-border);
  }
}
