#QuickRate {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--sp-sm);
  align-items: center;
  justify-content: center;
  background: var(--color-bg);
  // padding: var(--sp-sm);
  z-index: 669;
  max-width: 500px;
  min-height: calc(100vh - (var(--sp) * 24)) !important;
  margin: var(--gap) auto;
  overflow: hidden;

  @media (max-width: 840px) {
    position: fixed !important;
    min-height: 100vh !important;
    inset: 0;
    margin: 0;
  }

  .bigZap {
    width: var(--gap);
    height: var(--gap);
    animation: shadowTeaser 3s infinite ease-in-out;

    polygon {
      stroke-width: 0.5px;
    }
  }

  .end {
    display: flex;
    flex-direction: column;
    gap: var(--sp-sm);
    align-items: center;
    justify-content: center;

    .bigZap {
      animation: none;
    }

    h2 {
      margin-bottom: var(--sp-lg);
    }
  }

  .intro {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 667;

    @media (min-width: 840px) {
      display: none;
    }

    > * {
      padding: var(--sp-sm);
    }
    svg {
      width: var(--sp-md);
      height: var(--sp-md);
    }

    h1 {
      display: flex;
      align-items: center;
      gap: var(--sp);

      polygon {
        stroke-width: 1.5px;
      }
    }
  }

  .steps {
    position: absolute;
    top: var(--menu);
    display: flex;
    justify-content: center;
    gap: var(--sp);
    z-index: 667;

    @media (min-width: 840px) {
      top: var(--sp-sm);
    }

    li {
      position: relative;
      transition: var(--transition);
      transform: skew(-5deg);
      width: 3px;
      height: var(--sp-lg);
      background: var(--color-border);
      color: transparent;
      border-radius: 10px 0 10px 0;
      box-shadow: 1px 1px 1px #00000055;

      &:nth-child(even) {
        transform: scale(0.9, 1.15) skew(-3deg);
        border-radius: 0 10px 0 0;
      }

      &:nth-child(5),
      &:nth-child(8) {
        width: 2px;
        transform: scaleY(1.25) rotate(5deg);
        border-radius: 0 0 0 10px;
      }

      &:after {
        transition: var(--transition);
        transform-origin: top;
        content: "";
        position: absolute;
        inset: 0;
        background: var(--color-link);
        transform: scaleY(0);
        border-radius: inherit;
      }

      &.active {
        &:after {
          transform: scaleY(1);
        }
      }
    }
  }

  // Rate!
  .currentItem {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    transition: var(--transition);

    figure {
      display: block;
      aspect-ratio: 2/3;
      width: 100%;
      height: auto;
      margin: var(--menu) auto 0 auto;
      overflow: hidden;

      > * {
        aspect-ratio: inherit;
        height: auto !important;
      }

      &::after {
        content: "";
        position: absolute;
        inset: 0;
        background: linear-gradient(
          to top,
          var(--color-bg) 10%,
          transparent 90%,
          var(--color-bg)
        );
        z-index: 3;
      }
    }

    .content {
      transition: var(--transition);
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: var(--sp-sm);
      padding: 0 var(--sp-sm) calc(var(--sp-lg) + var(--app-bar));

      @media (min-width: 840px) {
        padding-bottom: 0;
      }

      header {
        text-shadow: 1px 1px 0 1px var(--color-bg),
          1px 1px 5px 1px var(--color-bg);
        text-align: center;

        h2 {
          color: var(--color-link);

          small {
            font-size: 1em;
            color: var(--color-border);
          }
        }

        p {
          cursor: pointer;
          transition: var(--transition);
          line-height: 1.3;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          max-height: 1lh;

          &[data-open="true"] {
            -webkit-line-clamp: 6;
            max-height: 6lh;
          }
        }
      }

      .RateItem {
        .ratings {
          padding: 0;

          li {
            border-radius: var(--sp-sm);

            button {
              color: var(--color-fg);

              &:hover {
                background: transparent;
              }

              &[data-selected="true"] {
                background: var(--color-primary);
              }
            }
          }
        }

        .user {
          display: none;
        }
      }

      .buttons {
        display: flex;
        gap: var(--sp-sm);
        justify-content: space-around;
      }
    }

    &[data-status="voted"] {
      transform: translateX(-125%) rotate(-15deg);
      opacity: 0;
    }

    &[data-status="new"] {
      transform: translateX(125%) rotate(15deg);
      opacity: 0;
    }

    &[data-status="voted"],
    &[data-status="waiting"],
    &[data-status="new"] {
      .content {
        opacity: 0;

        a,
        button {
          pointer-events: none;
        }
      }
    }
  }
}
