#header {
  position: absolute;
  top: var(--sp-sm);
  left: var(--sp-sm);
  z-index: 666;

  .search &,
  .quickrate & {
    display: none;
  }

  a {
    width: var(--sp-lg);
    position: relative;
    display: block;

    svg {
      width: 100%;
      height: auto;
      display: block;

      g {
        transition: var(--transition);
      }
    }
  }

  @media (min-width: 840px) {
    display: none;
  }
}
