.Hero {
  position: relative;
  display: flex;
  background: var(--color-bg);
  margin-bottom: var(--gap);
  height: var(--feature);

  .media {
    position: absolute;
    inset: 0;
    overflow: hidden;
    z-index: 1;

    > * {
      transition: opacity 1s ease-in-out;
      inset: 0;
      width: 100%;
      height: 100%;
      margin: auto;

      &.hidden {
        opacity: 0;
        z-index: 1;
      }

      &.poster {
        filter: blur(var(--sp-md));
        max-width: 900px;
      }
    }
  }

  &::before {
    content: "";
    transition: var(--transition);
    position: absolute;
    inset: 0;
    background: linear-gradient(to top, var(--color-bg), transparent 66%),
      radial-gradient(circle at -66% 0, var(--color-bg), transparent);
    z-index: 3;
  }

  &:not(.cover),
  &.person,
  &.user {
    &::before {
      background: linear-gradient(to top, var(--color-bg), transparent 66%);
    }
  }

  @media (min-width: 840px) {
    margin-right: calc(var(--sp) * -2);
    &::after {
      content: "";
      position: absolute;
      inset: 0;
      background: linear-gradient(to left, var(--color-bg), transparent 25%),
        linear-gradient(to right, var(--color-bg), transparent 25%);
      z-index: 2;
    }
  }

  &.small {
    height: var(--feature-small);
  }

  &.Loading {
    img {
      opacity: 0;
    }
  }
}
