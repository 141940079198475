.blurhash {
  position: relative;
  background: var(--color-bg-placeholder);
  overflow: hidden;
  transform: translateZ(0);
  outline: none;
  flex-shrink: 0;

  &.avatar {
    aspect-ratio: 1;
    height: var(--avatar-sm);
    border-radius: 100% !important;
  }

  > div {
    display: block !important;
    transition: var(--transition);
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
    inset: 0;
    z-index: 1;
    border-radius: inherit;
  }

  img {
    transition: var(--transition);
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    color: transparent;
    z-index: 2;
    border-radius: inherit;
  }
}
