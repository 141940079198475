.RangeSlider {
  .label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--sp);

    label {
      color: var(--color-border);
    }
  }

  .range-slider {
    touch-action: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    display: block;
    position: relative;
    width: 100%;
    height: var(--sp);
    background: var(--color-separator);
    border-radius: calc(var(--sp) / 2);

    .range-slider__thumb {
      position: absolute;
      z-index: 3;
      top: 50%;
      aspect-ratio: 1;
      width: var(--sp-sm);
      transform: translate(-50%, -50%);
      border-radius: 100%;
      background: var(--color-primary);
    }

    .range-slider__range {
      position: absolute;
      z-index: 1;
      transform: translate(0, -50%);
      top: 50%;
      width: 100%;
      height: 100%;
      background: var(--color-primary);
    }

    input[type="range"] {
      -webkit-appearance: none;
      pointer-events: none;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      background-color: transparent;
      box-shadow: none;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
      }

      &::-moz-range-thumb {
        width: 0;
        height: 0;
        border: 0;
      }

      &:focus {
        outline: 0;
      }
    }

    &[data-vertical] {
      height: 100%;
      width: var(--sp);

      .range-slider__thumb {
        left: 50%;
      }

      .range-slider__range {
        left: 50%;
        transform: translate(-50%, 0);
      }
    }

    &[data-disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
