.Score {
  &.negative {
    --color: var(--color-negative);
  }
  &.neutral {
    --color: var(--color-neutral);
  }
  &.positive {
    --color: var(--color-positive);
  }

  --bar-size: calc(var(--sp) / 1.5);
  gap: var(--sp) !important;
  font-weight: 900;
  color: var(--color);
  font-variant-numeric: tabular-nums;

  .bar {
    position: relative;
    aspect-ratio: 1;
    width: var(--sp-md);
    height: var(--sp-md);
    flex: 1;
    color: transparent;
    border-radius: 100%;

    &:before,
    &:after {
      content: "";
      position: absolute;
      border-radius: inherit;
    }

    &:before {
      inset: var(--bar-size);
      background: var(--color-bg);
      z-index: 1;
    }

    &:after {
      inset: var(--bar-size);
      box-shadow: 0 0 0 var(--bar-size) var(--color-border);
      z-index: -1;
      opacity: 0.5;
    }
  }
}
