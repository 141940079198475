.RateItem {
  position: relative;
  margin: auto;
  max-width: var(--feature);

  .ratings {
    transition: var(--transition);
    display: flex;
    justify-content: center;
    margin: auto;
    padding: var(--sp);

    li {
      animation-duration: 0.1s;
      animation-name: animate-pop;
      animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
      animation-fill-mode: backwards;
      overflow: hidden;
      flex: 0 0 20%;
      width: 20%;

      &:first-child {
        border-radius: var(--sp-sm) 0 0 var(--sp-sm);
      }

      &:last-child {
        border-radius: 0 var(--sp-sm) var(--sp-sm) 0;
      }

      @for $i from 1 through 5 {
        &:nth-child(#{$i}n) {
          animation-delay: #{$i * 0.1}s;
        }
      }

      @keyframes animate-pop {
        0% {
          opacity: 0;
          transform: scale(0.5, 0.5) translateY(50%);
        }
        100% {
          opacity: 1;
          transform: scale(1, 1);
        }
      }

      button {
        width: 100%;
        height: auto;
        padding: var(--sp) calc(var(--sp) / 2);
        color: var(--color-border);
        flex-direction: column;
        background: none;
        border-radius: 0;

        .EmojiScore {
          transition: var(--transition);
          width: 100%;

          &:after {
            content: "";
            transition: var(--transition);
            position: absolute;
            left: 8%;
            right: 8%;
            bottom: -10px;
            height: 8px;
            background: var(--color-bg-modal);
            border-radius: 50%;
            opacity: 1;
          }
        }

        small {
          text-transform: uppercase;
          line-height: 1;
        }

        &:hover {
          background: var(--color-separator);
          color: var(--color-link);
        }

        &[data-selected="true"] {
          background: var(--color-primary);
          color: var(--color-link);
        }
      }
    }
  }

  // When voting...
  &[data-voting="true"] {
    .Loader {
      top: 25%;
      left: 25%;
    }

    .ratings {
      li {
        button {
          pointer-events: none;
        }
      }
    }
  }

  &[data-voting="false"] {
    [data-selected="true"] {
      .EmojiScore {
        position: relative;

        &:after {
          animation: shadow 4s infinite ease-in-out;
        }

        svg {
          animation: float 4s infinite ease-in-out;
        }

        @keyframes float {
          0%,
          100% {
            transform: translateY(0);
          }

          50% {
            transform: scaleX(0.9666) translateY(-2px);
          }
        }

        @keyframes shadow {
          0%,
          100% {
            transform: scale(1);
          }

          50% {
            transform: scale(0.8);
          }
        }
      }
    }
  }

  // If voted..
  &[data-voted="true"] {
    .ratings {
      li {
        button {
          &:not([data-selected="true"]) {
            span {
              transform: scale(0.666);
              opacity: 0.666;
            }
          }
        }
      }
    }
  }
}
