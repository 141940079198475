@import "reset", "fonts", "root", "horizontalscroll", "scrollbar", "text",
  "button", "form", "modal", "blurhash", "toast", "offline";

html {
  font: var(--font-stack);
}

body {
  background: var(--color-bg);
  color: var(--color-fg);
  font: var(--font-stack);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media (max-width: 840px) {
    &.menuOpen {
      overflow: hidden !important;
    }
  }
}

#root {
  box-sizing: border-box;
  padding-bottom: calc(var(--menu) + var(--sp-lg));

  @media (min-width: 840px) {
    position: relative;
    margin: calc(var(--sp) * 8) auto 0 auto;
    padding: 0 0 var(--sp-md);

    &:has(.Fullscreen) {
      margin: 0;
    }
  }

  // App container
  #app {
    max-width: var(--max-width);
    margin: 0 auto;

    @media (min-width: 840px) {
      display: grid;
      gap: var(--sp-sm);
      align-items: flex-start;
      grid-template-columns: auto max(15vw, 280px);
    }

    main {
      position: relative;
      min-width: 0;

      section {
        position: relative;
        min-height: 100%;

        > .Loader {
          position: absolute;
          top: var(--sp-sm);
          right: var(--sp-sm);
          z-index: 665;
        }

        > * {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      /*
      @media (min-width: 840px) {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
      }
      */

      @media (max-width: 840px) {
        transition: transform 0.6s cubic-bezier(0.25, 1, 0.5, 1);

        .menuOpen & {
          pointer-events: none;
          transform: translateX(-90vw);
        }
      }
    }

    // Loading?
    &.loading {
      @keyframes pageLoad {
        from {
          width: 50%;
          left: -50%;
        }
        to {
          left: 100%;
          transform: scaleX(3);
        }
      }

      &:before {
        position: fixed;
        inset: 0;
        content: " ";
        width: 0;
        height: 3px;
        background-color: var(--color-primary);
        animation: pageLoad 2s infinite ease-in-out;
        animation-delay: 0.5s;
        transform-origin: 0% 100%;
        z-index: 999;
      }
    }
  }
}

svg {
  width: calc(var(--sp) * 2.5);
  height: calc(var(--sp) * 2.5);
}

hr {
  &.invisible {
    height: 0;
    border: none;
    margin: 0;
    outline: none;
  }
}
