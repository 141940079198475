.Loader {
  --size: calc(var(--sp) * 2);
  height: var(--size);
  width: var(--size);
  position: relative;
  border: 2px var(--color-primary) solid;

  border-top-color: transparent;
  animation: rotate 1s linear infinite;
  border-radius: 100%;

  &::after {
    content: "";
    position: absolute;
    inset: 2px;
    border: inherit;
    border-radius: inherit;
    animation: rotate 0.5s linear infinite reverse;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
