@use "_animations";

a {
  transition: var(--transition);
  color: var(--color-link);
  text-decoration: none;
}

.underline {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-decoration-color: var(--color-primary);
  text-underline-offset: 0.25em;

  &:hover {
    text-decoration-color: var(--color-secondary);
  }
}

.tMuted {
  color: var(--color-border);
}

.tTitle {
  font-weight: 600;
  font-family: var(--font-title);
  font-size: calc(var(--sp) * 4);
  line-height: calc(var(--sp) * 4.25);
}

.tSubtitle {
  font-weight: 600;
  color: var(--color-link);
  font-size: calc(var(--sp) * 2);
  line-height: calc(var(--sp) * 2);
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.cSubtitle {
  @extend .tSubtitle;
  position: relative;
  transition: var(--transition);
  display: flex;
  align-items: center;
  gap: var(--sp);
  padding: 0 var(--sp-sm) var(--sp-sm);
  margin-bottom: var(--sp-md);
  border-bottom: 1px var(--color-separator) solid;
  z-index: 1;

  > * {
    display: flex;
    align-items: inherit;
    gap: inherit;
    color: inherit;

    &.click {
      cursor: pointer;
    }
  }

  svg {
    stroke-width: 2.5px;
  }

  em {
    font-style: normal;
    color: var(--color-primary);
  }

  &.breadcrumb {
    > * {
      &:not(:first-child) {
        &::before {
          --size: var(--sp);
          content: "";
          display: block;
          aspect-ratio: 1;
          width: var(--size);
          border: var(--color-border) solid;
          border-width: 0 2px 2px 0;
          margin-right: calc(var(--size) / 2);
          transform: rotate(-45deg);
        }
      }

      &:last-child {
        color: var(--color-border);
      }
    }
  }
}

.tSmall {
  font-weight: 400;
  font-size: calc(var(--sp) * 1.6);
  line-height: var(--sp-sm);
  text-transform: uppercase;
}

.you {
  font-size: calc(var(--sp) * 1.25);
  font-weight: 500;
  line-height: 1;
  background: var(--color-secondary);
  color: var(--color-fg);
  padding: 0.4em 0.6em;
  border-radius: var(--sp);
  text-transform: uppercase;
}

.pill {
  font-size: 0.8em;
  line-height: 1;
  color: var(--color-border);
  background: var(--color-separator);
  padding: 0.25em 0.75em;
  border-radius: var(--sp);
}

.tError {
  font-size: calc(var(--sp) * 1.6);
  font-weight: 500;
  color: var(--color-negative);
}

.tMedium {
  font-weight: 500;
  font-size: calc(var(--sp) * 2);
  line-height: calc(var(--sp) * 2.5);
}

.tLarge {
  font-weight: 400;
  font-size: calc(var(--sp) * 2.5);
  line-height: calc(var(--sp) * 3.25);
}

.imgPlaceholder {
  @extend .skeleton;
}

.tPlaceholder {
  @extend .skeleton;
  border-radius: calc(var(--sp) / 2);
  overflow: hidden;

  * {
    color: inherit !important;
  }

  &::after {
    content: "\2800";
  }
}

.tPlaceholderMulti {
  @extend .skeleton;
  background: none;
  overflow: hidden;

  &::after {
    display: inline-flex;
    content: "All work and no play makes Jack a dull boy. All work and no play makes Jack a dull boy. All work and no play makes Jack a dull boy. All work and no play makes Jack a dull boy. All work and no play makes Jack a dull boy.";
    color: transparent;
    border-radius: var(--sp);
    background: var(--color-bg-placeholder);
    white-space: pre-wrap;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    z-index: 3;
  }
}

.emptyContent {
  margin: 0 auto var(--gap) auto;
  text-align: center;
  max-width: 80vw;

  p {
    color: var(--color-border);

    strong {
      font-weight: 500;
    }

    a {
      @extend .underline;
    }

    &:not(:last-child) {
      margin-bottom: var(--sp-sm);
    }
  }
}
