.AuthContent {
  min-height: var(--avatar);
  display: grid;
  place-items: center;

  .buttons {
    margin: auto;
    display: flex;
    gap: var(--sp);
    align-items: center;
    justify-content: center;
  }
}
