.ReactModal__Body--open {
  &:not(.search) {
    overflow: hidden !important;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 0.2s linear;
  background: var(--color-bg-modal) !important;
  backdrop-filter: blur(var(--sp));
  cursor: pointer;

  display: grid;
  place-items: center;

  &--after-open {
    opacity: 1;
  }

  &--before-close {
    opacity: 0;
  }
}

.ReactModal__Content {
  cursor: default;
  position: relative !important;
  transform: scale(0.9);
  //transform-origin: bottom;
  opacity: 0;
  transition: all 0.3s linear;

  inset: 0 !important;
  width: calc(100% - var(--sp-md) * 2);
  max-width: calc(var(--max-width) / 1.5);
  max-height: calc(100% - var(--sp-md) * 2);
  background: var(--color-bg) !important;
  border: none !important;
  box-shadow: 0 0 0 1px var(--color-border);
  border-radius: var(--sp-sm) !important;
  padding: 0 var(--sp-sm) var(--sp-sm) !important;
  overflow-x: hidden !important;
  outline: none;

  &--after-open {
    transition: all 0.2s linear;
    opacity: 1;
    transform: scale(1);
  }

  &--before-close {
    transform: translate(0, -5%);
    opacity: 0;
  }

  > * {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .cSubtitle {
    a,
    span {
      margin-left: auto;
    }
  }

  &.ModalImage {
    padding: 0 !important;
    max-width: 600px;

    &.poster {
      figure {
        aspect-ratio: 2/3;
      }
    }
  }

  &.trailer {
    overflow: hidden;

    .video {
      margin: calc(var(--sp-sm) * -1);
      aspect-ratio: 16 / 9;
      width: calc(100% + (var(--sp-sm) * 2)) !important;
      height: auto !important;
    }
  }

  // User interactions
  .userContent {
    box-shadow: 0 0 0 2px var(--color-border);
    border-radius: var(--sp-md);
    margin: 0 auto;
    max-width: calc(var(--max-width) / 1.5);
  }

  .user {
    position: relative;
    display: flex;
    align-items: center;
    gap: var(--sp);
    margin: 0 auto;
    padding: calc(var(--sp-sm) * 1.25) var(--sp-md) 0;
    max-width: calc(var(--max-width) / 1.5);

    &::before,
    &::after {
      transition: var(--transition);
      content: "";
      position: absolute;
      top: 0;
      left: calc(var(--sp) * 3.75);
      aspect-ratio: 1;
      width: var(--sp-sm);
    }

    &::before {
      border: 2px var(--color-border) solid;
      transform: translateY(-50%) rotate(45deg);
      clip-path: polygon(100% 100%, 0% 100%, 100% 0);
      z-index: 2;
    }

    &::after {
      border-top: 2px var(--color-bg) solid;
      z-index: 1;
    }

    figure {
      aspect-ratio: 1;
      flex-shrink: 0;
      width: calc(var(--sp) * 4);
      border-radius: 100%;
      overflow: hidden;
      background: var(--color-bg-placeholder);

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        color: transparent;
      }
    }

    p {
      line-height: 1.25;

      small {
        color: var(--color-border);
      }

      q {
        color: var(--color-link);
      }

      em {
        color: var(--color-link);
        font-weight: 900;
      }
    }

    button {
      margin-left: auto;
      color: var(--color-border);
    }
  }
}

// Fake hide/show to keep content
.ReactModal__Overlay {
  &.keep {
    transition: all 0.2s linear;
    position: fixed;
    inset: 0;

    &.visible {
      .ReactModal__Content {
        transition: all 0.2s linear;
        opacity: 1;
        transform: scale(1);
      }
    }

    &.hidden {
      opacity: 0;
      z-index: -1;

      .ReactModal__Content {
        transition: all 0.2s linear;
        transform: translate(0, -5%);
        opacity: 0;
      }
    }
  }
}
