:root {
  //Toastify
  --toastify-color-dark: #2f3148;
  --toastify-text-color-dark: var(--color-fg);
  --toastify-color-success: var(--color-positive);
  --toastify-color-warning: var(--color-neutral);
  --toastify-color-error: var(--color-negative);
  --toastify-toast-min-height: 1rem;
}

.Toastify {
  &__toast {
    font: inherit;
    border-radius: var(--sp-sm);
    padding: calc(var(--sp) * 1.5);
  }

  &__toast-body {
    padding: 0;
  }

  button {
    margin: 5px 4px 0 0;
    height: auto !important;
  }

  svg {
    path {
      fill: var(--color-link);
    }
  }
}
