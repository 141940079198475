.Fullscreen {
  height: 100%;
  position: fixed !important;
  inset: 0;
  display: grid;
  justify-content: center;
  align-items: center;
  // padding: 0 var(--sp-md) calc(var(--menu) + var(--sp-lg));
  padding: var(--sp-md);
  background: var(--color-bg);
  max-width: var(--max-width);
  min-height: 100vh !important;
  margin: 0;

  @media (min-width: 840px) {
    position: absolute !important;
    align-items: center;
    padding: 0;
  }

  img {
    position: absolute;
    inset: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    min-height: 100%;
    max-width: 100% !important;
    object-fit: cover;
    filter: grayscale(1);
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    inset: 0;
  }

  &::before {
    background: url(/img/noise.gif) center center no-repeat;
    background-size: cover;
    z-index: 2;
    mix-blend-mode: soft-light;
    opacity: 0.5;
  }

  &::after {
    background: radial-gradient(
      ellipse,
      var(--color-bg-modal),
      var(--color-bg) 66%
    );
    z-index: 3;
  }

  .text {
    position: relative;
    z-index: 4;
    text-align: center;
    color: var(--color-link);
    max-width: var(--feature) !important;

    h1 {
      font-family: var(--font-title);
      font-size: 2.25rem;
      line-height: 1.1;
      text-transform: uppercase;

      span {
        font-style: italic;
      }

      strong {
        font-weight: 900;
        color: var(--color-primary);
      }
    }

    p {
      font-size: 1.15rem;
      margin-top: var(--sp-sm);

      &.buttons {
        margin-top: var(--sp-lg);
        display: flex;
        align-items: center;
        justify-content: center;
        gap: var(--sp-sm);
      }
    }
  }
}
