#offline {
  transition: var(--transition);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: right;
  gap: var(--sp);
  z-index: 999;
  padding: var(--sp-sm) var(--sp-md);
  color: var(--color-link);
  background: var(--color-bg-nav);
  transform: translateY(-100%);

  svg {
    height: 14px;
  }

  &.active {
    transform: none;
  }
}
