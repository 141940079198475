#Home {
  .recommendations {
    position: relative;
  }

  .Posters {
    &.empty {
      ul {
        li {
          p {
            display: none;
          }
        }
      }
    }
  }
}
