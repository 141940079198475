@use "../../styles/_text";

.Hero.Item {
  align-items: flex-end;
  padding: 0 var(--sp-sm);
  margin-bottom: var(--sp-lg);

  &.user {
    margin-bottom: calc(var(--sp) * 6);
  }

  .header {
    position: relative;
    width: 100%;
    max-width: calc(var(--max-width) - var(--sp-lg));
    margin: 0 auto;
    z-index: 666;

    h1 {
      transition: var(--transition);
      color: var(--color-link);
      text-wrap: balance;
    }

    .info {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: var(--sp) var(--sp-sm);
      color: var(--color-border);

      &:first-child {
        margin-bottom: var(--sp);
      }

      .you {
        margin-left: calc(var(--sp) * -1.25);
      }

      &:last-child {
        margin-top: var(--sp-sm);
      }

      .icon {
        width: var(--sp-md);
        border-radius: 100%;
      }

      .title {
        color: var(--color-link);
        font-weight: 500;
      }

      > * {
        transition: var(--transition);
        display: flex;
        align-items: center;
        gap: calc(var(--sp) / 2);

        svg {
          width: var(--sp-sm);
          height: var(--sp-sm);
        }
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: var(--sp) var(--sp-sm);

        a.active {
          pointer-events: none;
        }

        a:not(.active) {
          color: inherit;
        }
      }
    }

    .ScoreStars {
      justify-content: center;
      margin-bottom: var(--sp-sm);
    }
  }

  &.small {
    height: var(--feature-small);

    .avatar {
      position: absolute;
      top: calc(var(--feature-small) / 2 - var(--avatar) / 1.33);
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
      width: var(--avatar);
      height: var(--avatar);
      border-radius: 100%;
      overflow: hidden;
      z-index: 4;

      > div {
        transition: var(--transition);
        width: 100% !important;
        height: 100% !important;
        position: absolute !important;
        top: 0;
        left: 0;
        z-index: 1;
      }

      img {
        position: relative;
        transition: var(--transition);
        display: block;
        aspect-ratio: 1;
        width: 100%;
        height: 100%;
        object-fit: cover;
        color: transparent;
        z-index: 2;
      }
    }

    .header {
      text-align: center;
      //  margin: 0 var(--menu);

      .info {
        justify-content: center;
      }
    }
  }

  &.film,
  &.show {
    .poster {
      display: none;
    }

    @media (min-width: 840px) {
      .header {
        display: flex;
        gap: var(--sp-md);
        align-items: flex-end;
        grid-template-columns: calc(var(--feature) / 3) auto;

        h1 {
          grid-row: span 3;
        }

        .poster {
          display: block;
          position: relative;
          transition: var(--transition);
          aspect-ratio: 2 / 3;
          width: calc(var(--feature) / 2);
          height: auto;
          grid-row: span 4;
          background: var(--color-bg-placeholder);
          border-radius: var(--sp);
          overflow: hidden;
          margin-bottom: calc(var(--sp) * -1);
        }
      }
    }
  }

  &.Loading {
    .header {
      h1,
      .info {
        @extend .tPlaceholder;
      }

      h1 {
        display: inline-block;
        min-width: 15ch;
      }

      .info {
        > * {
          opacity: 0;
        }
      }

      .poster {
        @extend .imgPlaceholder;
      }
    }

    &.user,
    &.person {
      .media {
        opacity: 0;
      }
    }

    .avatar {
      @extend .imgPlaceholder;

      > * {
        opacity: 0;
      }
    }
  }
}

.HeroSubtitle,
.HeroDescription {
  transition: var(--transition);
  margin: calc(var(--sp-sm) * -1) auto var(--sp-lg);
  padding: 0 var(--sp-sm);
  max-width: 60ch !important;
  text-align: center;

  &.Loading {
    p {
      @extend .tPlaceholderMulti;
      &:after {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }
    }
  }
}

.HeroSubtitle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--sp);
  color: var(--color-border);

  img {
    aspect-ratio: 1;
    width: var(--sp-lg);
    border-radius: 100%;
  }
}
