.ItemGenres {
  --itemHeight: calc(var(--sp) * 6);

  ul {
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: var(--itemHeight);
      --color: var(--color-border);

      &:not(:last-child) {
        border-bottom: 1px var(--color-separator) solid;
      }

      a {
        color: var(--color);
      }

      &[data-status="voted"] {
        --color: var(--color-link);
        font-weight: 500;
      }
    }
  }

  > ul {
    border-bottom: 1px var(--color-separator) solid;
  }

  .otherGenres {
    --max-height: calc(var(--itemHeight) * 10);

    &.voted {
      --max-height: max(
        calc(var(--itemHeight) * (10 - var(--voted-genres))),
        calc(var(--itemHeight) * 3)
      );
    }

    position: relative;
    transition: var(--transition);
    position: relative;

    max-height: var(--max-height);
    overflow: hidden;

    .showMore {
      opacity: 0;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
    }

    &.closed {
      .showMore {
        opacity: 1;
        top: var(--max-height);
        transform: translateY(-100%);
        z-index: 5;
      }
    }

    &.open {
      max-height: var(--genres-height);
    }
  }
}
