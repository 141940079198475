// Filters
@use "../styles/_text";

#Menu.searchFilters {
  .wrap {
    > div {
      padding: var(--padding);
    }
  }

  .ais-RefinementList {
    button {
      margin-top: var(--sp);
    }

    ul {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;

      li {
        label {
          display: flex;
          align-items: center;
          gap: var(--sp);
          padding: calc(var(--sp) / 2) 0;

          .ais-RefinementList-count {
            @extend .pill;
          }
        }

        &.ais-RefinementList-item--selected {
          color: var(--color-link);
        }
      }
    }
  }

  .ais-ClearRefinements {
    margin-top: auto;
    padding-top: var(--sp-sm);
  }
}
