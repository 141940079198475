#Menu {
  --padding: var(--sp) var(--sp-sm);
  position: sticky;
  top: calc(var(--menu) - var(--sp));
  z-index: 667;

  .wrap {
    min-height: calc(100vh - var(--menu) - var(--sp-md));
    display: flex;
    flex-direction: column;
    background: var(--color-bg);
    padding: 0 var(--sp-sm);
    border-left: 1px var(--color-separator) solid;
    overflow: auto;
  }

  @media (max-width: 840px) {
    transition: var(--transition);
    overflow: hidden;

    .wrap,
    .trap {
      position: fixed;
      height: 100vh;
      width: 90vw;
      inset: 0;
      transition: transform 0.6s cubic-bezier(0.25, 1, 0.5, 1);
      transform: translateX(100vw);
    }
    .wrap {
      transition: transform 0.6s cubic-bezier(0.25, 1, 0.5, 1);
      left: auto;
      padding-bottom: calc(var(--menu) + var(--sp-sm));

      z-index: 2;
    }

    .trap {
      transition: opacity 0.6s cubic-bezier(0.25, 1, 0.5, 1);
      cursor: pointer;
      z-index: 1;
      background: var(--color-bg-modal) !important;
      backdrop-filter: blur(var(--sp));

      opacity: 0;
    }

    .menuOpen & {
      z-index: 667;

      .wrap,
      .trap {
        transform: none;
        opacity: 1;
      }
    }
  }

  .tSubtitle {
    color: var(--color-primary);
    padding: var(--padding);
    margin-top: var(--sp-sm);

    &:not(:first-of-type) {
      padding-top: var(--sp-md);
      border-top: 1px var(--color-separator) solid;
    }

    &.about {
      margin-top: auto;
    }
  }

  #Footer {
    padding-top: var(--sp-sm);
    border-top: 1px var(--color-separator) solid;
  }

  .user,
  .buttons {
    border-bottom: 1px var(--color-separator) solid;
  }

  .user {
    font-weight: 500;

    .userIcon {
      aspect-ratio: 1;
      width: var(--avatar-md);
      height: var(--avatar-md);
      box-sizing: content-box;
      display: block;
      margin: var(--sp-sm) var(--sp-sm) 0;
      background: var(--color-bg-placeholder);
      border-radius: 100%;

      svg {
        opacity: 0;
      }

      &.active {
        pointer-events: none;
      }
    }
  }

  .buttons {
    margin-top: var(--sp-sm);
    display: flex;
    padding-bottom: var(--sp-sm);
    justify-content: center;
    gap: var(--sp-sm);
    border-bottom: 1px var(--color-separator) solid;

    @media (min-width: 840px) {
      button {
        justify-content: center;
      }
    }
  }

  .avatar {
    aspect-ratio: 1;
    transition: var(--transition);
    background: var(--color-bg-placeholder);
    color: transparent;
    border-radius: 100%;
    object-fit: cover;
  }

  ul {
    margin-bottom: var(--sp-sm);

    li {
      > * {
        display: flex;
        align-items: center;
        padding: var(--padding);
        gap: var(--sp-sm);

        svg,
        .avatar {
          display: block;
          width: calc(var(--sp) * 2);
          height: calc(var(--sp) * 2);
          stroke-width: 1.5px;
        }

        .pill {
          margin: 0 0 0 -4px;
        }
      }

      a {
        color: var(--color-border);

        &.active {
          pointer-events: none;
          color: var(--color-link);
        }

        &.hot {
          color: var(--color-tertiary);
          svg {
            stroke-width: 2px;
          }
        }
      }
    }

    &.extras {
      border-top: 1px var(--color-separator) solid;
      padding-top: var(--sp-sm);

      li {
        a {
          color: var(--color-border);
          padding: calc(var(--sp) / 2) var(--sp-sm);
        }
      }
    }
  }

  p {
    padding: var(--padding);
    max-width: 80ch;
  }
}
