.LaunchStreaming {
  overflow: hidden;
  min-width: 0;
  padding-bottom: var(--sp);

  @media (min-width: 840px) {
    //margin: calc(var(--sp) * -1) 0 0;
  }

  ul {
    align-items: center;
    gap: 1px;

    li {
      button,
      .btn {
        border-radius: 0;
        padding: 0 1em;

        img {
          border-radius: calc(var(--sp) / 2);
        }

        .emoji {
          font-size: 1.25em;
        }

        &.watch {
          em {
            font-style: normal;
            color: var(--color-border);
          }
        }
      }

      &:first-child {
        > * {
          font-weight: 600;
          border-radius: var(--sp) 0 0 var(--sp);
        }
      }

      &:last-child {
        > * {
          border-radius: 0 var(--sp) var(--sp) 0;
        }
      }
    }
  }
}

.Streaming {
  position: relative;
  padding: var(--sp-sm);

  ul {
    --size: calc(var(--sp) * 6);
    display: flex;
    flex-direction: column;
    gap: var(--sp-sm);

    &:not(:first-of-type) {
      padding-top: var(--sp-sm);
      margin-top: var(--sp-sm);
      border-top: 1px var(--color-separator) solid;
    }

    li {
      a {
        display: flex;
        align-items: center;
        gap: var(--sp-sm);

        img {
          display: block;

          aspect-ratio: 1;
          width: var(--size);
          height: auto;
          flex: 0 0 var(--size);
          border-radius: var(--sp);
          color: transparent;
          background: var(--bg-placeholder);
        }

        p {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          line-height: 1;
          gap: calc(var(--sp) / 2);
          font-weight: 500;

          small {
            font-size: 0.67rem;
            text-transform: uppercase;
            color: var(--color-border);
          }
        }
      }
    }
  }
}
