.Collections {
  padding: 0 var(--sp-sm);
  margin-bottom: var(--gap);

  ul {
    display: flex;
    flex-direction: column;
    gap: var(--sp-md);

    li {
      .item {
        display: flex;
        align-items: center;
        gap: var(--sp-sm);

        .posters {
          display: flex;
          flex-wrap: wrap;
          flex-shrink: 0;
          aspect-ratio: 1;
          border-radius: var(--sp);
          height: var(--collection);
          overflow: hidden;
          background: var(--color-bg-placeholder);
          gap: 1px;

          figure {
            position: relative;
            aspect-ratio: 1;
            flex: 0 0 calc(50% - 1px);
            color: transparent;
          }

          &.i3 {
            figure {
              &:nth-child(3) {
                flex: 0 0 100%;
              }
            }
          }
        }
        .text {
          .tSmall {
            display: flex;
            gap: var(--sp);
            color: var(--color-border);
            align-items: center;
          }

          .item {
            margin-bottom: var(--sp);
          }
        }
      }
    }
  }
}
