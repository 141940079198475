@use "../../styles/_text";

.ScoreSomething {
  transition: var(--transition);
  display: inline-flex;

  align-items: center;
  font-variant-numeric: tabular-nums;
  color: var(--color);

  background: var(--color-bg-widget);
  padding: 0 calc(var(--sp) / 2);
  border-radius: var(--sp);

  &[data-status="voted"] {
    color: var(--color-link);

    button {
      color: var(--color-link);
    }
  }

  &[data-enabled="false"] {
    button {
      //  color: var(--color-border);
      opacity: 0.35;
      pointer-events: none;
    }
  }

  &[data-voting="true"] {
    opacity: 0.3;

    button {
      pointer-events: none;
    }
  }

  button {
    padding: calc(var(--sp) / 2);
    color: inherit;
    margin-top: calc(var(--sp) / 2.5 * -1);

    &[data-rated="true"] {
      // pointer-events: none;
      svg {
        transform: scale(1.25);
        fill: var(--color-primary);
        stroke: var(--color-bg-widget);
      }
    }

    &.down {
      transform: translateY(2px);
    }
  }

  .score {
    @extend .tSmall;
    box-sizing: content-box;
    min-width: calc(var(--sp) * 2.5);
    padding: calc(var(--sp) / 2);
    text-align: center;
    font-weight: 500;
  }

  .Loader {
    margin: auto;
  }
}
