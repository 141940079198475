.Percentage {
  position: relative;
  padding: var(--sp) var(--sp-sm) 0;
  margin: var(--sp-lg) 0 var(--sp-md);
  display: flex;
  flex-direction: column;
  gap: var(--sp);
  font-variant-numeric: tabular-nums;

  .text {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    p {
      color: var(--color-border);
      line-height: 1;

      strong {
        display: inline-block;
        color: var(--color-link);
        font-weight: inherit;
      }
    }

    .percentage {
      line-height: 1;
      display: flex;
      align-items: baseline;
      gap: 2px;
      font-weight: 700;
      color: var(--color-link);

      small {
        font-size: 1em;
        font-weight: 400;
      }
    }
  }

  .progress {
    position: relative;
    width: 100%;
    height: var(--sp);
    border-radius: var(--sp);
    overflow: hidden;
    background: var(--color-separator);

    p {
      width: 0;
      transition: 1.5s ease-out;
      position: absolute;
      inset: 0;
      right: auto;
      color: transparent;
      background: var(--color-primary);

      &[data-progress="100"] {
        background: var(--color-positive);
      }
    }
  }
}
