.Review {
  --max-height: calc((var(--sp-md)) * 6);
  position: relative;
  display: flex;
  gap: var(--sp-sm);
  align-items: flex-start;

  &:not(:last-child) {
    padding-bottom: var(--sp-md);
    border-bottom: 1px var(--color-separator) solid;
  }

  .poster {
    aspect-ratio: 2/3;
    position: relative;
    display: block;
    flex: 0 0 auto;
    width: var(--collection);
    height: auto;
    border-radius: var(--sp);
    overflow: hidden;

    figure,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .text {
    z-index: 2;
  }

  header {
    display: flex;
    align-items: center;
    gap: var(--sp-sm);
    margin: 0 0 var(--sp-sm) 0;

    figure {
      aspect-ratio: 1;
      width: var(--avatar-sm);
      border-radius: 100%;
      background: var(--color-bg-placeholder);

      img {
        aspect-ratio: 1;
      }
    }
  }

  /*
  .rating {
    position: absolute;
    //top: -0.25rem;
    top: calc(var(--sp) / -2);
    // left: calc(var(--avatar-sm) - 1.25rem);
    left: calc(var(--avatar-sm) - 1em + (var(--sp) / 2));
    font-size: 1.75rem;
    background: var(--color-bg);
    padding: calc(var(--sp) / 2);
    border-radius: 100%;
  }
  */

  .title {
    margin-bottom: var(--sp);
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    gap: 0.25em 0.4em;

    .user {
      small {
        color: var(--color-border);
      }
    }
  }

  .author {
    display: flex;
    align-items: center;
    gap: var(--sp);
    color: var(--color-border);
    line-height: 1;
    min-height: 0;

    a {
      color: inherit;
    }

    .you {
      display: inline-flex;
      transform: translateY(-1px);
      margin: 0 2px;
    }

    span {
      flex-shrink: 0;
    }

    .ScoreStars {
      gap: 3px;
      margin-top: -1px;

      svg {
        width: calc(var(--sp) * 1.5);
      }
    }
  }

  .content {
    .review {
      transition: var(--transition);
      position: relative;

      max-height: var(--max-height);
      overflow: hidden;

      p {
        &:not(:last-child) {
          padding-bottom: var(--sp);
        }
      }

      a {
        display: block;
        color: inherit;

        span {
          display: inline-block;
          vertical-align: baseline;
          color: var(--color-border);
        }
      }

      .showMore {
        opacity: 0;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
      }

      &.closed {
        .showMore {
          opacity: 1;
          top: var(--max-height);
          transform: translateY(-100%);
          z-index: 5;
        }
      }

      &.open {
        max-height: var(--review-height);
      }

      .preview & {
        .preview {
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          padding: 0;
          margin-bottom: calc(var(--sp) / 2);

          @media (min-width: 840px) {
            -webkit-line-clamp: 2;
          }
        }

        .more {
          color: var(--color-border);
        }
      }
    }
  }

  .ScoreSomething {
    margin-top: var(--sp-sm);
    .score {
      text-align: center;
    }
  }

  &.Full {
    padding: 0 var(--sp-sm);

    .content {
      .review {
        max-height: var(--review-height);
      }
    }
  }
}
