@use "../styles/_text";

#Suggest {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 667;
  background: var(--color-bg);
  min-height: calc(100vh - (var(--sp) * 18)) !important;

  > div {
    display: flex;
    flex-direction: column;
    gap: var(--sp-sm);
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: auto !important;
    max-width: 340px;
  }

  .end {
    .buttons {
      display: flex;
      align-items: center;
      gap: inherit;
    }
  }

  form {
    margin-top: var(--sp-lg);
    display: flex;
    flex-direction: column;
    gap: var(--sp-md);

    div {
      display: flex;

      align-items: center;
    }
    label {
      &:not(:has(input)) {
        @extend .tSmall;
        color: var(--color-border);
        margin-right: var(--sp-md);
      }

      &:has(input) {
        display: inline-flex;
        align-items: center;
        gap: var(--sp);

        &:not(:last-child) {
          margin-right: var(--sp-sm);
        }
      }
    }

    input {
      &[type="text"] {
        font-size: calc(var(--sp) * 2);
      }

      &#year {
        width: 4ch;
      }
    }
  }
}
