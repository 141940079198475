/* Scrollbar */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--color-border) var(--color-bg);
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
*::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: #1b1c29;
}

*::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #8d99ae;
}

*::-webkit-scrollbar-thumb:active {
  background-color: var(--color-fg);
}
