@use "../styles/_text";

#Docs {
  position: relative;
  padding: var(--sp-md) var(--sp-sm) 0;
  z-index: 667;
  min-height: 50vh !important;
  background: var(--color-bg);
  font-size: 0.9rem;
  line-height: 1.4;

  > * {
    margin-bottom: var(--sp-md);
  }

  h1 {
    @extend .tTitle;
  }

  h2 {
    @extend .tSubtitle;
  }

  ::marker {
    color: var(--color-primary);
  }

  ul,
  ol {
    list-style: initial;

    padding: 0 0 0 var(--sp-sm);
  }

  a {
    text-decoration: underline;
  }
}
