.actions {
  --button: calc(var(--sp) * 5);
  transition: var(--transition);
  position: fixed;
  top: var(--sp-sm);
  right: var(--sp-sm);
  display: flex;
  gap: var(--sp);
  z-index: 667;
  transform: translateX(calc(var(--button) + var(--sp-sm)));
  overflow: hidden;

  li {
    position: relative;
    transition: var(--transition);
    max-width: var(--button);
    overflow: hidden;

    &.score {
      max-width: calc(var(--button) * 4);
    }

    @for $i from 1 through 5 {
      &:nth-child(#{$i}n) {
        transition-delay: #{$i * 0.1}s;
        z-index: #{$i + 1};
      }
    }

    button {
      --height: var(--button);
    }

    small {
      font-variant-numeric: tabular-nums;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      font-size: 0.65rem;
      line-height: 1;
      padding: calc(var(--sp) / 2);
      // background: inherit;
      border-radius: 100%;
    }

    .ScoreSomething {
      height: var(--button);
      padding: 0 var(--sp-sm);
      border-radius: var(--sp-md);
    }

    &.toggle {
      button {
        border-radius: 0;
      }
    }
  }

  .loading & {
    opacity: 0;

    li {
      opacity: 0;
      transform: scale(0);
    }
  }

  &.scrolled {
    transform: translateX(0);
    border-radius: var(--sp-md);
    gap: 0;

    li {
      button {
        border-radius: 0 !important;
      }

      .ScoreSomething {
        border-radius: var(--sp);
      }

      &.toggle {
        z-index: 6;

        button {
          border-radius: 100%;
        }
      }
    }

    &.hidden {
      li {
        &:not(.toggle) {
          max-width: 0;
          opacity: 0.6;
          transform: scaleX(0);
          transform-origin: right;

          @for $i from 1 through 5 {
            &:nth-child(#{$i}n) {
              transform: translateX(calc(var(--button) * #{5 - $i}));
              z-index: #{$i + 1};
            }
          }
        }

        &.toggle {
          button {
            background: var(--color-secondary);
            svg {
              transform: rotate(0);
            }
          }
        }
      }
    }

    &.visible {
      background: var(--color-bg);
      border-radius: var(--sp);

      li {
        &.toggle {
          svg {
            transform: rotate(225deg);
          }
        }
      }
    }
  }

  @media (min-width: 840px) {
    --button: calc(var(--sp) * 6);
    position: sticky;
    top: calc(var(--menu) + var(--sp) - 1px);
    width: fit-content;
    margin: 0 0 calc(var(--button) * -1) auto;
    justify-content: flex-end;
    border-radius: var(--sp);
    overflow: hidden;

    &.visible {
      li {
        &.toggle {
          //   margin-left: var(--sp);
        }
      }
    }
  }

  #Profile & {
    position: absolute;
    transform: none;
    margin: 0;
    top: var(--sp-sm);

    @media (min-width: 840px) {
      right: 0;
    }
  }
}
