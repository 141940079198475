.HorizontalScroll {
  position: relative;
  height: 100%;

  ul {
    display: flex;
    scroll-snap-type: x proximity;
    overflow-x: auto;

    @media (hover: hover) {
      transition: all 0.3s;

      &:not(:hover) {
        scrollbar-color: transparent transparent;

        &::-webkit-scrollbar-thumb {
          background: transparent;
        }
      }
    }

    /*
    &::after {
      content: "";
      width: 1px;
      flex: 0 0 1px;
    }
    */

    li {
      position: relative;
      flex-shrink: 0;
      scroll-snap-align: start;
    }
  }
}
