#Lists {
  .genres {
    --max-height: calc((100vw / 3) * 3);
    transition: var(--transition);
    position: relative;
    margin-bottom: var(--gap);
    max-height: var(--max-height);
    overflow: hidden;

    ul {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: var(--sp-md) var(--sp-lg);
      padding: 0 var(--sp-sm);
      margin-bottom: var(--gap);

      @media (min-width: 840px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }

      li {
        width: 100%;

        a {
          position: relative;
          display: flex;
          align-items: center;
          gap: var(--sp-sm);
          overflow: hidden;

          figure {
            position: relative;
            aspect-ratio: 1;
            height: var(--collection);
            background: var(--color-bg-placeholder);
            color: transparent;
            border-radius: var(--sp);
          }

          .text {
            line-height: 1.25;

            .description {
              margin-top: var(--sp);
              color: var(--color-border);
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
            }
          }
        }
      }
    }

    .showMore {
      opacity: 0;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
    }

    &.closed {
      .showMore {
        opacity: 1;
        top: var(--max-height);
        transform: translateY(-100%);
        z-index: 5;
      }
    }

    &.open {
      max-height: var(--genres-height);
    }
  }
}
