#Profile {
  position: relative;
  .notVerified {
    text-align: center;
    padding: 0 var(--sp-sm);

    p {
      margin-bottom: var(--sp-md);

      &.tSubtitle {
        margin-bottom: var(--sp);
      }
    }
  }

  .editPicture {
    transition: var(--transition);
    position: absolute;
    top: calc(var(--feature-small) / 2 - var(--avatar) / 1.33);
    right: calc(50% - var(--avatar) / 2);
    z-index: 666;
    width: var(--avatar);
    height: var(--avatar);
    background: var(--color-bg);
    border-radius: 100%;
    transform: scale(0.35) translate(15%, -15%);
    transform-origin: top right;
    opacity: 0.5;

    .btn {
      width: 100%;
      height: 100%;
      transform: scale(2.75);
    }

    input {
      display: none;
    }

    &.disabled {
      pointer-events: none;
      transform: none;
      background: var(--color-primary);

      .btn {
        transform: scale(1);
      }
    }
  }
}
