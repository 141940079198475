.ReviewItem {
  .review {
    position: relative;
    padding: var(--sp-md);

    h2 {
      color: var(--color-border);
      margin: 0 0 var(--sp-sm) 0;
    }
  }
  form {
    position: relative;

    textarea {
      padding: var(--sp-sm) var(--sp-sm) 0;
      width: 100%;
      overflow: hidden;
      border: none;
      background: none;
      box-shadow: none;
      min-height: calc(var(--sp-md) * 4);
      line-height: var(--sp-md);
    }

    .characters {
      font-size: 0.85rem;
      font-weight: 500;
      position: absolute;
      bottom: var(--sp-sm);
      right: var(--sp-sm);
      opacity: 0.3;
    }
  }

  .action {
    padding: var(--sp-sm);
    text-align: center;
  }

  &[data-reviewing="true"] {
    cursor: default;
    pointer-events: none;

    .review {
      opacity: 0.5;
    }
  }
}
