#Footer {
  svg {
    display: none;
  }

  p {
    font-size: 0.9rem;
    line-height: 1.5;

    a {
      text-decoration: underline;
      text-underline-offset: 3px;
      text-decoration-color: var(--fg-disabled);
      color: inherit;
    }

    &:first-of-type {
      span {
        display: block;
      }
    }

    &.small,
    &.legal {
      font-size: 0.85em;
      color: var(--color-border);
    }

    &.legal {
      padding-top: 0;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: calc(var(--sp) / 2);
      text-transform: uppercase;
      font-size: 0.7em;
    }
  }

  @media (min-width: 840px) {
    margin-top: var(--gap);
    border-top: 1px var(--color-separator) solid;
    padding-top: var(--sp-md);
    padding-right: var(--sp-md);
    display: grid;
    grid-template-columns: var(--sp-lg) 1fr 1fr auto;
    align-items: start;
    gap: var(--sp-md);

    h4 {
      display: none;
    }

    svg {
      display: block;
      width: 100%;
      height: auto;
    }

    p {
      &.small {
        font-size: 0.9rem;
      }

      &.legal {
      }
    }
  }
}
