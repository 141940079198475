.ItemDetails {
  display: flex;
  flex-direction: column;
  gap: calc(var(--sp) / 2);

  &.person {
    padding: 0 var(--sp-sm);
  }

  dt {
    color: var(--color-border);
  }

  dd {
    margin: 0 0 var(--sp-sm) 0;

    ul {
      display: flex;
      flex-direction: column;
      gap: calc(var(--sp) / 2);

      li {
        display: flex;
        flex-wrap: wrap;
        gap: 0 var(--sp);
        align-items: center;
        position: relative;
        font-variant-numeric: tabular-nums;

        &::before {
          content: "";
          width: var(--sp);
          border-bottom: 1px var(--color-primary) solid;
        }
      }
    }
  }
}
