.UserLists {
  display: flex;
  flex-direction: column;

  ul {
    transition: var(--transition);
    display: flex;
    flex-direction: column;
    gap: var(--sp-md);
    transform-origin: bottom;

    &[data-show="true"] {
      opacity: 0;
      max-height: 0;
      overflow: hidden;
    }

    li {
      transition: var(--transition);

      label {
        display: flex;
        align-items: center;
        gap: var(--sp);
        transition: var(--transition);
        cursor: pointer;
      }

      .icon {
        flex-shrink: 0;
        display: grid;
        place-items: center;
        aspect-ratio: 1;
        width: calc(var(--sp) * 5);
        background: var(--color-separator);
        border-radius: var(--sp);
        overflow: hidden;

        img,
        svg {
          aspect-ratio: 1;
          stroke-width: 1px;
        }

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .name {
        flex: 1;
        min-width: 0;
        overflow: hidden;

        .title {
          font-weight: 500;
          display: block;
          align-items: center;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .number {
          display: block;
          color: var(--color-border);
        }
      }

      .action {
        transition: var(--transition);
        display: grid;
        place-items: center;
        width: var(--sp-md);
        aspect-ratio: 1;
        flex-shrink: 0;
        box-shadow: 0 0 0 2px var(--color-separator);
        border-radius: 100%;

        input {
          background: none !important;
          box-shadow: none;
        }
      }

      &:nth-child(4) {
        padding-top: var(--sp-sm);
        border-top: 1px var(--color-separator) solid;
      }

      &.default {
        .name {
          .title {
            display: flex;
            svg {
              aspect-ratio: 1;
              height: 0.8em;
              order: 1;
            }
          }
        }
      }

      &.not-interested {
        padding-bottom: var(--sp-sm);
        border-bottom: 1px var(--color-separator) solid;
        color: var(--color-border);

        .icon {
          height: var(--sp-md);
          background: none;

          svg {
            width: var(--sp-sm);
            height: var(--sp-sm);
          }
        }

        .name {
          .number {
            display: none;
          }
        }

        .action {
          display: none;
        }
      }

      &.active {
        color: var(--color-primary);

        .title {
          font-weight: 700;
        }

        .action {
          box-shadow: 0 0 0 2px var(--color-primary);
        }
      }

      &[data-disabled="true"] {
        pointer-events: none;

        &:not(.active) {
          opacity: 0.4;
        }
      }

      &[data-loading="true"] {
        cursor: default;
        pointer-events: none;
      }
    }
  }

  .add {
    text-align: center;

    &:not(.open) {
      margin-top: var(--sp-md);
      padding-top: var(--sp-md);
      border-top: 1px var(--color-separator) solid;
    }

    svg {
      transition: var(--transition);

      height: auto;
      stroke-width: 3px;
    }

    &.open {
      svg {
        transform: scale(1.15) rotate(-135deg);
      }
    }
  }

  .newList {
    transition: var(--transition);
    position: relative;
    margin-top: var(--sp-sm);
    display: flex;
    align-items: center;
    padding: 0 var(--sp) 0 0;
    background: var(--color-bg-placeholder);
    box-shadow: 0 0 0 1px transparent;
    border-radius: var(--sp-md);

    opacity: 0;
    max-height: 0;
    overflow: hidden;

    &[data-show="true"] {
      opacity: 1;
      max-height: calc(var(--sp) * 10);
      box-shadow: 0 0 0 1px var(--color-separator);
    }

    &:focus-within {
      box-shadow: 0 0 0 1px var(--color-border);
    }

    input {
      transition: var(--transition);
      flex: 1;
      background: none !important;
      padding: var(--sp-sm);
      box-shadow: none;
    }

    &[data-loading="true"] {
      pointer-events: none;

      input {
        opacity: 0.5;
      }
    }
  }

  .tError {
    text-align: center;
    margin-top: var(--sp);
  }
}
