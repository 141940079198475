input,
textarea,
select {
  transition: var(--transition);
  font: var(--font-stack);
  font-weight: 500;
  color: var(--color-border);
  background: var(--color-bg-placeholder);
  padding: 0.75em;
  border: none;
  border-radius: var(--sp);
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
  box-shadow: 0 0 0 1px var(--color-separator);

  &:focus {
    color: var(--color-fg);
    box-shadow: 0 0 0 1px var(--color-border);
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }

  &[type="text"] {
    box-sizing: content-box;
  }

  &[type="checkbox"],
  &[type="radio"] {
    cursor: pointer;
    transition: var(--transition);
    display: inline-flex;
    position: relative;
    appearance: none;
    background-color: var(--color-fg);
    margin: 0;
    padding: 0;
    font: inherit;
    color: currentColor;
    aspect-ratio: 1;
    width: var(--sp-sm);
    height: var(--sp-sm);
    border: none;
    border-radius: calc(var(--sp) / 3);

    &::before {
      content: "";
      position: absolute;
      inset: calc(var(--sp) / 4);
      transform: scale(0);
      transition: all 0.1s ease-in-out;
      box-shadow: inset 1em 1em var(--color-primary);
      background: var(--color-primary);
      border-radius: calc(var(--sp) / 4);
    }

    &:checked {
      background: var(--color-link);

      &::before {
        transform: scale(1);
      }
    }

    &.list {
      &:checked {
        background: var(--color-bg);
        box-shadow: none;
        border-radius: 0;

        &::before {
          background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23f72585' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E")
            center center no-repeat;
          background-size: contain;
          inset: 0;
          box-shadow: none;
        }
      }
    }
  }

  &[type="radio"] {
    border-radius: 100%;

    &::before {
      border-radius: 100%;
    }
  }
}
