button,
.btn,
input[type="button"],
input[type="submit"],
input[type="reset"] {
  --height: calc(var(--sp) * 5);
  transition: var(--transition);
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  height: var(--height);
  padding: 0 calc(var(--height) / 2);
  gap: calc(var(--height) / 4);

  font: var(--font-stack);
  font-weight: 500;
  color: var(--color-link);
  background: var(--color-primary);

  border: none;
  border-radius: var(--sp-sm);

  img,
  svg {
    transition: var(--transition);
    width: calc(var(--sp) * 2);
    height: calc(var(--sp) * 2);
    //stroke-width: 3px;
  }

  .Loader {
    border-color: currentColor;
    border-top-color: transparent;
  }

  &:hover {
    background: var(--color-secondary);
  }

  &.disabled,
  &[disabled] {
    pointer-events: none;
    color: var(--color-fg-disabled);
    background: var(--color-bg-disabled);
  }

  // Secondary
  &.subtle {
    background: var(--color-subtle);

    &:hover {
      background: var(--color-secondary);
    }
  }

  // Over picture
  &.over {
    background: var(--color-bg);
  }

  // Outline
  &.outline {
    background: none;
    box-shadow: 0 0 0 2px var(--color-link) inset;

    &:hover {
      box-shadow: 0 0 0 2px var(--color-primary) inset;
    }

    &.simple {
      box-shadow: 0 0 0 1px var(--color-link) inset;

      &:hover {
        box-shadow: 0 0 0 1px var(--color-primary) inset;
      }
    }

    &.disabled,
    &[disabled] {
      box-shadow: 0 0 0 2px var(--color-fg-disabled) inset;
    }
  }

  // Small
  &.small {
    --height: calc(var(--sp) * 4);
    font-size: calc(var(--sp) * 1.5);
  }

  // large
  &.large {
    --height: calc(var(--sp) * 6);
    font-size: calc(var(--sp) * 2);
    font-weight: 600;
  }

  // Round
  &.round {
    aspect-ratio: 1;
    padding: 0;
    display: grid;
    place-items: center;
    border-radius: 100%;
  }

  // Just icon
  &.icon {
    background: none;
    border-radius: 0;
  }

  // Show more
  &.showMore {
    flex-wrap: wrap;
    width: 100%;
    height: calc(var(--sp) * 7);
    gap: 0;
    align-items: flex-end;
    justify-content: center;
    border-radius: 0;
    background: linear-gradient(to top, var(--color-bg), transparent);
    color: var(--color-border);

    &:hover {
      color: var(--color-link);
    }

    svg {
      width: calc(var(--sp) * 3);
      height: auto;
    }

    small {
      flex: 0 0 100%;
      font-weight: 500;
      margin-top: -1em;
    }
  }
}
