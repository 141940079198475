.Teaser {
  position: absolute;
  inset: 0;
  display: flex;

  align-items: center;
  justify-content: center;

  background: radial-gradient(circle, transparent, var(--color-bg));
  z-index: 2;
  gap: var(--sp-md);
  padding: 0 var(--sp-sm);

  .EmojiScore {
    position: relative;
    aspect-ratio: 1;
    height: 40%;

    svg {
      animation: floatTeaser 4s infinite ease-in-out;

      g {
        path {
          fill: var(--color-bg) !important;

          &:nth-child(1),
          &:nth-child(2) {
            transform-origin: center;
            animation: eyes 12s infinite ease-in-out;
          }
        }
      }
    }

    &:after {
      content: "";
      transition: var(--transition);
      position: absolute;
      left: 8%;
      right: 8%;
      bottom: -10px;
      height: 8px;
      background: var(--color-separator);
      border-radius: 50%;
      opacity: 1;
      animation: shadowTeaser 4s infinite ease-in-out;
    }

    @keyframes floatTeaser {
      0%,
      100% {
        transform: translateY(0);
      }

      50% {
        transform: scaleX(0.95) translateY(-5%);
      }
    }

    @keyframes shadowTeaser {
      0%,
      100% {
        transform: scale(1);
      }

      50% {
        transform: scale(0.9);
        opacity: 0.4;
      }
    }

    @keyframes eyes {
      0%,
      40%,
      65%,
      80%,
      85% {
        translate: 0;
        transform: scale(1);
      }

      50%,
      62% {
        translate: 5% 0;
      }

      82% {
        transform: scaleY(0.1);
      }
    }

    svg {
      height: 100%;
    }
  }

  .text {
    position: relative;
    background: var(--color-subtle);
    padding: var(--sp-sm) var(--sp-md);
    border-radius: var(--sp-md);

    &::before {
      position: absolute;
      top: 50%;
      right: 100%;
      content: "";
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: var(--sp-sm) var(--sp-md) var(--sp-sm) 0;
      border-color: transparent var(--color-subtle) transparent transparent;
      transform: translateY(-50%);
    }
  }

  p {
    color: var(--color-link);
    text-align: center;
    line-height: 1.55;
    font-weight: 400;
    max-width: 25ch;

    @media (min-width: 840px) {
      font-size: 1.25em;
    }

    .btn {
      margin-top: var(--sp-sm);
    }
  }
}
