.ExternalReviews {
  --innerGap: var(--sp-md);
  --limit: 3;
  --height: calc(var(--sp) * 6.5);
  transition: var(--transition);
  position: relative;
  margin-bottom: var(--gap);
  max-height: calc(
    (var(--height) + var(--innerGap)) * var(--limit) - var(--innerGap)
  );
  overflow: hidden;

  &.More {
    max-height: calc(
      (var(--height) + var(--innerGap)) * var(--total) - var(--innerGap)
    );

    .showMore {
      display: none;
    }
  }

  .showMore {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: var(--innerGap);
    padding: 0 var(--sp-sm);

    li {
      a {
        display: block;
        min-width: 0;

        p {
          text-wrap: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &.author {
            display: flex;
            align-items: flex-start;
            gap: var(--sp);

            img {
              aspect-ratio: 1;
              flex: 0 0 var(--sp-md);
              width: var(--sp-md);
              height: auto;
              border-radius: 100%;
            }

            strong {
              font-weight: 500;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            small {
              font-weight: 400;
              color: var(--color-border);
            }
          }

          &.description {
            margin-top: 0.25rem;
            font-weight: 400;
            color: var(--color-border);
          }
        }
      }
    }
  }
}
