.Filters {
  box-sizing: border-box;
  position: relative;
  transition: var(--transition);
  display: flex;
  gap: var(--sp);
  align-items: center;
  justify-content: flex-end;
  color: var(--color-border);
  min-height: var(--sp-lg);

  max-width: calc(var(--max-width) - var(--sp-lg)) !important;
  margin: 0 0 var(--sp-sm);
  padding: 0 0 calc(var(--sp) / 2);
  // box-shadow: 0 -1px 0 var(--color-separator) inset;

  @media (max-width: 840px) {
    padding-inline: var(--sp-sm);
  }

  &[data-intab="true"] {
    max-width: calc(var(--max-width) + var(--sp-lg)) !important;
    margin: 0 calc(var(--sp) * -2) var(--sp-md);
    padding-inline: var(--sp-sm);
  }

  &[data-inuser="true"] {
    // margin: calc(var(--sp) * -1) 0 var(--sp-md);
  }

  &[data-noparent="true"] {
    max-width: var(--max-width) !important;
    padding-inline: var(--sp-sm);
  }

  .label {
    margin-right: auto;
    font-variant-numeric: tabular-nums;
    letter-spacing: 1px;
  }

  input,
  select {
    box-sizing: content-box;
    cursor: pointer;
    opacity: 1;
    margin-right: calc(var(--sp) * -1 + 2px);
    border-radius: var(--sp) 0 0 var(--sp);
    padding: 0 var(--sp) 0 0.75em;
    height: var(--sp-lg);
    box-shadow: none;
    background: var(--color-separator);
  }

  input {
    max-width: 0;
    opacity: 0;
    background: none;
    color: transparent;
  }

  // Icons
  .icon {
    aspect-ratio: 1;
    color: currentColor;
    padding: var(--sp);
    width: var(--sp-lg);
    height: auto;
    background: var(--color-separator);
    border-radius: var(--sp);

    svg {
      > * {
        transition: var(--transition);
      }
    }

    &.search {
      position: relative;

      &:before,
      &:after {
        transition: var(--transition);
        content: "";
        position: absolute;
        inset: 9px;
        left: calc(50%);
        width: 1.5px;
        background-color: var(--color-border);
        transform: scale(0);
      }
    }

    &.sort {
      border-radius: 0 var(--sp) var(--sp) 0;

      &.asc {
        svg {
          transform: rotate(540deg);
        }
      }
    }

    &.layout {
      &.Rows {
        svg {
          rect {
            &:nth-child(1),
            &:nth-child(4) {
              width: calc(var(--sp) * 2.5);
            }
            &:nth-child(2),
            &:nth-child(3) {
              opacity: 0;
              transform: translateY(100%);
            }
          }
        }
      }

      &.List {
        svg {
          rect {
            height: 1px;
            width: 100%;
            stroke-width: 1px;

            &:nth-child(2) {
              transform: translate(-11px, 9px);
            }

            &:nth-child(3) {
              opacity: 0;
            }

            &:nth-child(4) {
              transform: translate(0, 7px);
            }
          }
        }
      }
    }
  }

  &.searching {
    input {
      opacity: 1;
      max-width: 15ch;
      box-shadow: 0 0 0 1px var(--color-separator) inset;
      color: var(--color-fg);
    }

    .icon,
    .field {
      &.search {
        margin-right: calc(var(--sp) * -2);
        border-radius: 0 var(--sp) var(--sp) 0;

        &:before {
          transform: scale(1) rotate(45deg);
        }
        &:after {
          transform: scale(1) rotate(-45deg);
        }

        circle {
          transform-origin: center center;
          transform: scale(1.25) translate(4%, 4%);
          opacity: 0;
        }

        line {
          transform-origin: top left;
          transform: scale(0);
        }
      }

      &:not(.search) {
        max-width: 0;
        padding: 0;
        opacity: 0;
      }
    }
  }

  .loading & {
    opacity: 0;
  }
}
