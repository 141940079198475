.People {
  --limit: 5;
  --height: var(--avatar-sm);
  box-sizing: content-box;
  margin-bottom: var(--gap);
  transition: var(--transition);
  max-height: calc(
    (var(--height) + var(--sp-md)) * var(--limit) - var(--sp-md)
  );
  overflow: hidden;

  ul {
    display: flex;
    gap: var(--sp-md);
    flex-direction: column;
    padding: 0 var(--sp-sm);

    li {
      a {
        display: flex;
        align-items: center;
        gap: var(--sp-sm);
        overflow: hidden;

        figure {
          flex-shrink: 0;
        }

        p {
          width: 100%;
          line-height: 1;

          > * {
            display: block;
          }

          strong {
            font-weight: 500;

            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          span {
            margin-top: calc(var(--sp) / 2);
            color: var(--color-border);
          }
        }
      }
    }
  }

  &.More {
    max-height: calc(
      (var(--height) + var(--sp-md)) * var(--total) - var(--sp-md)
    );

    .showMore {
      display: none;
    }
  }

  &.HorizontalScroll {
    max-height: 100%;

    @media (min-width: 840px) {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: var(--sp-sm);
        background: linear-gradient(to left, var(--color-bg), transparent);
      }
    }

    ul {
      //margin-left: var(--sp-sm);
      padding-left: 0;
      flex-direction: row;

      scroll-padding-left: var(--sp-sm);
      overflow-y: hidden;

      li {
        flex: 0 0 min(36.66%, calc(var(--sp) * 18));
        max-width: min(36.66%, calc(var(--sp) * 18));

        &:first-child {
          margin-left: var(--sp-sm);
        }

        a {
          flex-direction: column;
          text-align: center;

          figure {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }

  // If it's shown inside a tab...
  .react-tabs &,
  .ReactModalPortal & {
    margin: 0;

    &:not(:first-child) {
      margin-top: var(--sp-md);
      padding-top: var(--sp-md);
      border-top: 1px var(--color-subtle) solid;
    }

    ul {
      padding: 0;
    }
  }
}
