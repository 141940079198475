.Seasons {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: var(--sp-md);

  article {
    display: flex;
    flex-direction: column;
    gap: var(--sp-sm);

    &:not(:last-child) {
      padding-bottom: var(--sp-md);
      border-bottom: 1px var(--color-separator) solid;
    }

    header {
      display: flex;
      align-items: center;
      gap: var(--sp-sm);

      figure {
        position: relative;
        aspect-ratio: 2/3;
        width: var(--avatar-sm);
        height: auto;
        flex-shrink: 0;
        border-radius: var(--sp);
        overflow: hidden;
      }

      h3 {
        font-size: 1em;
        font-weight: normal;
        margin-bottom: calc(var(--sp) / 2);
      }

      .tSmall {
        color: var(--color-border);
      }
    }

    .description {
      transition: var(--transition);
      position: relative;
    }
  }
}
