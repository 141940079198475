#Review {
  .ReviewDetail {
    padding: 0 var(--sp-sm);
    margin-bottom: var(--sp-lg);
    border-bottom: 1px var(--color-separator) solid;

    > * {
      font-size: calc(var(--sp) * 2);
      line-height: calc(var(--sp) * 3.25);
      margin-bottom: 1.5em;
      max-width: 70ch;
    }
  }
}
