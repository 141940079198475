.Reviews {
  position: relative;
  padding: 0 var(--sp-sm);
  margin-bottom: var(--gap);

  .items {
    display: flex;
    flex-direction: column;
    gap: var(--sp-md);
  }
}
